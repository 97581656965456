var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "items order-index-page main-content" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c(
            "Header",
            {
              attrs: {
                title: _vm.$tc("orders.order", 2),
                "bread-crumb-links": _vm.breadCrumbLinks,
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.totalPendingOrders ||
                        _vm.totalCompletedOrders ||
                        _vm.filtersApplied,
                      expression:
                        "totalPendingOrders || totalCompletedOrders || filtersApplied",
                    },
                  ],
                  staticClass: "mr-4 mb-3 mb-sm-0",
                },
                [
                  _c(
                    "base-button",
                    {
                      attrs: {
                        outline: true,
                        icon: _vm.filterIcon,
                        size: "large",
                        color: "theme",
                        "right-icon": "",
                      },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "/orders/create" },
                      slot: "item-title",
                    },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            size: "large",
                            icon: "plus",
                            color: "theme",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("orders.new_order")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters",
              },
            ],
            staticClass: "filter-section",
          },
          [
            _c("div", { staticClass: "filter-container" }, [
              _c(
                "div",
                { staticClass: "filter-customer" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$tc("customers.customer", 1)) + " "),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    ref: "customerSelect",
                    attrs: {
                      options: _vm.sundryDebtorsList,
                      required: "required",
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      label: "name",
                      "track-by": "id",
                    },
                    on: {
                      select: _vm.onSelectCustomer,
                      deselect: _vm.clearCustomerSearch,
                    },
                    model: {
                      value: _vm.filters.customer,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "customer", $$v)
                      },
                      expression: "filters.customer",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "filter-date" }, [
                _c(
                  "div",
                  { staticClass: "from pr-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.from")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                      },
                      model: {
                        value: _vm.filters.from_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "from_date", $$v)
                        },
                        expression: "filters.from_date",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dashed" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "to pl-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.to")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                      },
                      model: {
                        value: _vm.filters.to_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "to_date", $$v)
                        },
                        expression: "filters.to_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-order" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("orders.order_number")))]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: { icon: "hashtag" },
                    model: {
                      value: _vm.filters.order_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "order_number", $$v)
                      },
                      expression: "filters.order_number",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "clear-filter", on: { click: _vm.clearFilter } },
              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" },
        },
        [
          _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(_vm._s(_vm.$t("orders.no_orders"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" },
              },
              [_vm._v(_vm._s(_vm.$t("orders.list_of_orders")))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mt-3",
                  attrs: { outline: true, color: "theme", size: "large" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("orders/create")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("orders.new_order")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "table-container",
        },
        [
          _c(
            "div",
            { staticClass: "table-actions mt-5" },
            [
              _c("h4", [_vm._v("Pending Orders")]),
              _vm._v(" "),
              _c("p", { staticClass: "table-stats" }, [
                _vm._v(_vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.pendingOrders.length))]),
                _vm._v(" " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.pending_filtered_count))]),
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.selectedOrders && _vm.selectedOrders.length
                    ? _c(
                        "v-dropdown",
                        { attrs: { "show-arrow": false } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "table-actions-button dropdown-toggle",
                              attrs: { slot: "activator", href: "#" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: { click: _vm.removeMultipleOrders },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "trash"] },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectAllFieldStatus,
                  expression: "selectAllFieldStatus",
                },
              ],
              staticClass: "custom-control-input",
              attrs: { id: "select-all", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.selectAllFieldStatus)
                  ? _vm._i(_vm.selectAllFieldStatus, null) > -1
                  : _vm.selectAllFieldStatus,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.selectAllFieldStatus,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.selectAllFieldStatus = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectAllFieldStatus = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectAllFieldStatus = $$c
                    }
                  },
                  _vm.selectAllOrders,
                ],
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isRequestOngoing,
                    expression: "!isRequestOngoing",
                  },
                ],
                staticClass: "custom-control-label selectall",
                attrs: { for: "select-all" },
              },
              [
                _c("span", { staticClass: "select-all-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.select_all")) + " "),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.getPendingOrders
            ? _c(
                "table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.getPendingOrders.data,
                    "table-class": "table",
                  },
                },
                [
                  _c("table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "custom-control custom-checkbox",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectField,
                                        expression: "selectField",
                                      },
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: { id: row.id, type: "checkbox" },
                                    domProps: {
                                      value: row.id,
                                      checked: Array.isArray(_vm.selectField)
                                        ? _vm._i(_vm.selectField, row.id) > -1
                                        : _vm.selectField,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectField,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = row.id,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectField = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectField = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectField = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", {
                                    staticClass: "custom-control-label",
                                    attrs: { for: row.id },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1359643308
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.number"),
                      show: "order_number",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      path: `orders/${row.id}/edit?d=true`,
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n             " +
                                      _vm._s(row.order_number) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1662050310
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.date"),
                      "sort-as": "order_date",
                      show: "formattedOrderDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.name"),
                      width: "20%",
                      show: "master.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.count"),
                      width: "20%",
                      show: "order_items.length",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: _vm.$t("orders.quantity"), width: "20%" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.order_items.reduce(
                                      (i, j) => i + parseInt(j.quantity),
                                      0
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      791605162
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.remaining_quantity"),
                      width: "20%",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.order_items.reduce(
                                      (i, j) =>
                                        i + parseInt(j.remaining_quantity),
                                      0
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      948914249
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("orders.action"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-dropdown",
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { slot: "activator", href: "#" },
                                      slot: "activator",
                                    },
                                    [_c("dot-icon")],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown-item",
                                    [
                                      _vm.role === "admin" ||
                                      _vm.role === "accountant"
                                        ? _c(
                                            "router-link",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: {
                                                to: {
                                                  path: `orders/${row.id}/edit`,
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "dropdown-item-icon",
                                                attrs: {
                                                  icon: ["fas", "pencil-alt"],
                                                },
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t("general.edit")
                                                  ) +
                                                  "\n              "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            to: {
                                              path: `orders/${row.id}/view`,
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: { icon: "eye" },
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("orders.view")) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-dropdown-item", [
                                    _vm.role === "admin" ||
                                    _vm.role === "accountant"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeOrder(row.id)
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: ["fas", "trash"] },
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.delete")
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      548521180
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "table-container",
        },
        [
          _c(
            "div",
            { staticClass: "table-actions mt-5" },
            [
              _c("h4", [_vm._v("Completed Orders")]),
              _vm._v(" "),
              _c("p", { staticClass: "table-stats" }, [
                _vm._v(_vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.completedOrders.length))]),
                _vm._v(" " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.completed_filtered_count))]),
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.selectedOrders && _vm.selectedOrders.length
                    ? _c(
                        "v-dropdown",
                        { attrs: { "show-arrow": false } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "table-actions-button dropdown-toggle",
                              attrs: { slot: "activator", href: "#" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: { click: _vm.removeMultipleOrders },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "trash"] },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectAllFieldStatus,
                  expression: "selectAllFieldStatus",
                },
              ],
              staticClass: "custom-control-input",
              attrs: { id: "select-all", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.selectAllFieldStatus)
                  ? _vm._i(_vm.selectAllFieldStatus, null) > -1
                  : _vm.selectAllFieldStatus,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.selectAllFieldStatus,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.selectAllFieldStatus = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectAllFieldStatus = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectAllFieldStatus = $$c
                    }
                  },
                  _vm.selectAllOrders,
                ],
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isRequestOngoing,
                    expression: "!isRequestOngoing",
                  },
                ],
                staticClass: "custom-control-label selectall",
                attrs: { for: "select-all" },
              },
              [
                _c("span", { staticClass: "select-all-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.select_all")) + " "),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.getCompletedOrders
            ? _c(
                "table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.getCompletedOrders.data,
                    "table-class": "table",
                  },
                },
                [
                  _c("table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "custom-control custom-checkbox",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectField,
                                        expression: "selectField",
                                      },
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: { id: row.id, type: "checkbox" },
                                    domProps: {
                                      value: row.id,
                                      checked: Array.isArray(_vm.selectField)
                                        ? _vm._i(_vm.selectField, row.id) > -1
                                        : _vm.selectField,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectField,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = row.id,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectField = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectField = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectField = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", {
                                    staticClass: "custom-control-label",
                                    attrs: { for: row.id },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1359643308
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.number"),
                      show: "order_number",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    to: {
                                      path: `orders/${row.id}/edit?d=true`,
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n             " +
                                      _vm._s(row.order_number) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2347782245
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.date"),
                      "sort-as": "order_date",
                      show: "formattedOrderDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.name"),
                      width: "20%",
                      show: "master.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.count"),
                      width: "20%",
                      show: "order_items.length",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: _vm.$t("orders.quantity"), width: "20%" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.order_items.reduce(
                                      (i, j) => i + parseInt(j.quantity),
                                      0
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      791605162
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("orders.remaining_quantity"),
                      width: "20%",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    row.order_items.reduce(
                                      (i, j) =>
                                        i + parseInt(j.remaining_quantity),
                                      0
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      948914249
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("orders.action"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-dropdown",
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { slot: "activator", href: "#" },
                                      slot: "activator",
                                    },
                                    [_c("dot-icon")],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-dropdown-item"),
                                  _vm._v(" "),
                                  _c("v-dropdown-item", [
                                    _vm.role === "admin" ||
                                    _vm.role === "accountant"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeOrder(row.id)
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: ["fas", "trash"] },
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.delete")
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2908859276
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }