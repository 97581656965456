var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "items main-content" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c(
            "Header",
            {
              attrs: {
                title: _vm.$tc("items.bill_ty", 2),
                "bread-crumb-links": _vm.breadCrumbLinks,
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.totalItemsToBe > 0 ||
                        _vm.totalItems > 0 ||
                        _vm.filtersApplied,
                      expression:
                        "totalItemsToBe>0 || totalItems>0 || filtersApplied",
                    },
                  ],
                  staticClass: "mr-4 mb-3 mb-sm-0",
                },
                [
                  _c(
                    "base-button",
                    {
                      attrs: {
                        outline: true,
                        icon: _vm.filterIcon,
                        color: "theme",
                        size: "large",
                        "right-icon": "",
                      },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "bill-ty/create" },
                      slot: "item-title",
                    },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            color: "theme",
                            icon: "plus",
                            size: "large",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("items.add_bill")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters",
              },
            ],
            staticClass: "filter-section",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-4" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(" " + _vm._s(_vm.$tc("items.party_name")) + " "),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    ref: "customerSelect",
                    attrs: {
                      options: _vm.sundryDebtorsList,
                      required: "required",
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      label: "name",
                      "track-by": "id",
                    },
                    on: {
                      select: _vm.onSelectCustomer,
                      deselect: _vm.clearCustomerSearch,
                    },
                    model: {
                      value: _vm.filters.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "name", $$v)
                      },
                      expression: "filters.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-4" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(" " + _vm._s(_vm.$tc("items.bill_ty")) + " "),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      type: "text",
                      name: "bill_ty",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.bill_ty,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "bill_ty", $$v)
                      },
                      expression: "filters.bill_ty",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "clear-filter", on: { click: _vm.clearFilter } },
                [_vm._v(" " + _vm._s(_vm.$t("general.clear_all")))]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" },
        },
        [
          _c("satellite-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(_vm._s(_vm.$t("items.no_items"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" },
              },
              [_vm._v(_vm._s(_vm.$t("items.list_of_items")))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mt-3",
                  attrs: { outline: true, color: "theme", size: "large" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("bill-ty/create")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("items.add_new_item")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.showEmptyScreen
        ? _c("div", [
            _c("h4", [_vm._v("Pending Bill-Ty")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-container" },
              [
                _c(
                  "div",
                  { staticClass: "table-actions mt-5" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade" } },
                      [
                        _vm.selectedItemsToBe.length
                          ? _c(
                              "v-dropdown",
                              { attrs: { "show-arrow": false } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "table-actions-button dropdown-toggle",
                                    attrs: { slot: "activator", href: "#" },
                                    slot: "activator",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.actions")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("v-dropdown-item", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: _vm.removeMultipleItemsToBe,
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "dropdown-item-icon",
                                        attrs: { icon: ["fas", "trash"] },
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("general.delete")) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "custom-control custom-checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectAllFieldStatusToBe,
                        expression: "selectAllFieldStatusToBe",
                      },
                    ],
                    staticClass: "custom-control-input",
                    attrs: { id: "select-all-to-be", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectAllFieldStatusToBe)
                        ? _vm._i(_vm.selectAllFieldStatusToBe, null) > -1
                        : _vm.selectAllFieldStatusToBe,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.selectAllFieldStatusToBe,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.selectAllFieldStatusToBe = $$a.concat([
                                  $$v,
                                ]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectAllFieldStatusToBe = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectAllFieldStatusToBe = $$c
                          }
                        },
                        _vm.selectAllItemsToBe,
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isRequestOngoing,
                          expression: "!isRequestOngoing",
                        },
                      ],
                      staticClass: "custom-control-label selectall",
                      attrs: { for: "select-all-to-be" },
                    },
                    [
                      _c("span", { staticClass: "select-all-label" }, [
                        _vm._v(_vm._s(_vm.$t("general.select_all")) + " "),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "table-component",
                  {
                    ref: "table",
                    attrs: {
                      data: _vm.fetchDataToBe,
                      "show-filter": false,
                      "table-class": "table",
                    },
                  },
                  [
                    _c("table-column", {
                      attrs: {
                        sortable: false,
                        filterable: false,
                        "cell-class": "no-click",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.selectFieldToBe,
                                          expression: "selectFieldToBe",
                                        },
                                      ],
                                      staticClass: "custom-control-input",
                                      attrs: { id: row.id, type: "checkbox" },
                                      domProps: {
                                        value: row.id,
                                        checked: Array.isArray(
                                          _vm.selectFieldToBe
                                        )
                                          ? _vm._i(
                                              _vm.selectFieldToBe,
                                              row.id
                                            ) > -1
                                          : _vm.selectFieldToBe,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.selectFieldToBe,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = row.id,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.selectFieldToBe =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.selectFieldToBe = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.selectFieldToBe = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: row.id },
                                    }),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4101857072
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("items.party_name"),
                        show: "party_name",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: { path: `bill-ty/${row.id}/edit` },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          row.party_name
                                            ? row.party_name
                                            : row.name
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3430154544
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("items.invoice_number"),
                        show: "invoice_number",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row && row.dispatch
                                        ? row.dispatch.name
                                        : row.name
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        866120824
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("items.bill_ty"),
                        show: "bill_ty",
                      },
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("items.added_on"),
                        "sort-as": "created_at",
                        show: "formattedCreatedAt",
                      },
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: "Image", show: "images" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return row.images
                                ? [
                                    _c("expandable-image", {
                                      staticClass: "image",
                                      attrs: {
                                        src: row.images.original_image_path,
                                      },
                                    }),
                                  ]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      key: Math.random(),
                      attrs: {
                        sortable: false,
                        filterable: false,
                        "cell-class": "action-dropdown",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("items.action")) + " "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-dropdown",
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { slot: "activator", href: "#" },
                                        slot: "activator",
                                      },
                                      [_c("dot-icon")],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-dropdown-item",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: {
                                              to: {
                                                path: `bill-ty/${row.id}/edit`,
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: {
                                                icon: ["fas", "pencil-alt"],
                                              },
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.$t("general.edit")) +
                                                "\n                "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-dropdown-item", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeItems(row.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: { icon: ["fas", "trash"] },
                                          }),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("general.delete")) +
                                              "\n                "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2866121756
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("h4", [_vm._v("Completed Bill-Ty")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-container" },
              [
                _c(
                  "div",
                  { staticClass: "table-actions mt-5" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade" } },
                      [
                        _vm.selectedItems.length
                          ? _c(
                              "v-dropdown",
                              { attrs: { "show-arrow": false } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "table-actions-button dropdown-toggle",
                                    attrs: { slot: "activator", href: "#" },
                                    slot: "activator",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.actions")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("v-dropdown-item", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-item",
                                      on: { click: _vm.removeMultipleItems },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "dropdown-item-icon",
                                        attrs: { icon: ["fas", "trash"] },
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("general.delete")) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "custom-control custom-checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectAllFieldStatus,
                        expression: "selectAllFieldStatus",
                      },
                    ],
                    staticClass: "custom-control-input",
                    attrs: { id: "select-all", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectAllFieldStatus)
                        ? _vm._i(_vm.selectAllFieldStatus, null) > -1
                        : _vm.selectAllFieldStatus,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.selectAllFieldStatus,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.selectAllFieldStatus = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectAllFieldStatus = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectAllFieldStatus = $$c
                          }
                        },
                        _vm.selectAllItems,
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isRequestOngoing,
                          expression: "!isRequestOngoing",
                        },
                      ],
                      staticClass: "custom-control-label selectall",
                      attrs: { for: "select-all" },
                    },
                    [
                      _c("span", { staticClass: "select-all-label" }, [
                        _vm._v(_vm._s(_vm.$t("general.select_all")) + " "),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "table-component",
                  {
                    ref: "tableToBe",
                    attrs: {
                      data: _vm.fetchData,
                      "show-filter": false,
                      "table-class": "table",
                    },
                  },
                  [
                    _c("table-column", {
                      attrs: {
                        sortable: false,
                        filterable: false,
                        "cell-class": "no-click",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.selectField,
                                          expression: "selectField",
                                        },
                                      ],
                                      staticClass: "custom-control-input",
                                      attrs: { id: row.id, type: "checkbox" },
                                      domProps: {
                                        value: row.id,
                                        checked: Array.isArray(_vm.selectField)
                                          ? _vm._i(_vm.selectField, row.id) > -1
                                          : _vm.selectField,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.selectField,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = row.id,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.selectField = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.selectField = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.selectField = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: row.id },
                                    }),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1359643308
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("items.party_name"),
                        show: "party_name",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      to: { path: `bill-ty/${row.id}/edit` },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          row.party_name
                                            ? row.party_name
                                            : row.name
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3689334739
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("items.invoice_number"),
                        show: "invoice_number",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row && row.dispatch
                                        ? row.dispatch.name
                                        : row.name
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        866120824
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("items.added_on"),
                        "sort-as": "created_at",
                        show: "formattedCreatedAt",
                      },
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: "Image", show: "images" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return row.images
                                ? [
                                    _c("expandable-image", {
                                      staticClass: "image",
                                      attrs: {
                                        src: row.images.original_image_path,
                                      },
                                    }),
                                  ]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      key: Math.random(),
                      attrs: {
                        sortable: false,
                        filterable: false,
                        "cell-class": "action-dropdown",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("items.action")) + " "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-dropdown",
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { slot: "activator", href: "#" },
                                        slot: "activator",
                                      },
                                      [_c("dot-icon")],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-dropdown-item",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: {
                                              to: {
                                                path: `bill-ty/${row.id}/edit`,
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: {
                                                icon: ["fas", "pencil-alt"],
                                              },
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.$t("general.edit")) +
                                                "\n                "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-dropdown-item", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeItems(row.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: { icon: ["fas", "trash"] },
                                          }),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("general.delete")) +
                                              "\n                "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2866121756
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }